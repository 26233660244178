.seriesYihuoPage {
    .seriesYihuoPage__info {
        position: relative;

        .seriesYihuoPage__info-img {
            width: 100%;
            height: auto;
            font-size: 0;
        }

        .seriesYihuoPage__infoContent {
            position: absolute;
            width: 100%;
            bottom: 50px;
            z-index: 1;
            text-align: center;

            .seriesYihuoPage__infoContent-tl {
                .seriesYihuoPage__infoContent-tlImg {
                    width: 110px;
                    height: auto;
                }
            }

            .seriesYihuoPage__infoContent-text {
                padding-top: 10px;

                p {
                    font-family: SourceHanSansSC;
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 2;
                    color: #969696;
                }
            }
        }

        .seriesYihuoPage__middleTitle {
            position: absolute;
            left: 0;
            bottom: 10px;
            width: 100%;
            text-align: center;
            font-family: WenCang;
            font-size: 18px;
            color: #969696;
        }

        .seriesYihuoPage__infoThree {
            position: absolute;
            left: 0;
            top: 20px;
            width: 100%;
            text-align: center;

            p {
                font-family: SourceHanSansSC;
                font-size: 12px;
                color: #969696;
                line-height: 2;
            }
        }
    }

    .seriesYihuoPage__infoFourWrap {
        background: url(../../assets/series/yihuo/info_6.jpg) no-repeat;
        background-size: 100% auto;
        padding-top: 150px;
        padding-bottom: 220px;
        .seriesYihuoPage__items {
             .seriesYihuoPage__itemCol {
                display: flex;
                padding: 0 30px;
                margin-bottom: 20px;
                .seriesYihuoPage__itemCol-img {
                    width: 142px;
                    height: auto;
                }

                .seriesYihuoPage__itemComtent {
                    margin-left: 20px;
                    .seriesYihuoPage__itemTl {
                        margin-top: 10px;
                        font-family: WenCang;
                        font-size: 14px;
                        color: #969696;
                    }

                    .seriesYihuoPage__itemDesc {
                        font-family: SourceHanSansSC;
                        font-size: 12px;
                        color: #969696;
                    }
                }
            }
        }
    }

    .seriesYihuoPage__infoFive {
        position: absolute;
        left: 0;
        bottom: 100px;
        width: 100%;
        text-align: center;

        p {
            font-family: SourceHanSansSC;
            font-size: 20px;
            color: #969696;
            line-height: 1;
        }
    }

    .seriesYihuoPage__infoSeven {
        position: absolute;
        left: 0;
        top: 40%;
        width: 100%;
        text-align: center;

        p {
            font-family: SourceHanSansSC;
            font-size: 12px;
            color: #969696;
            line-height: 2;
        }
    }

    .seriesYihuoPage__product {
        padding-top: 10px;
        padding-bottom: 10px;
        background: url(../../assets/series/yihuo/info_5.jpg) no-repeat;
        background-size: 100% auto;

        .seriesYihuoPage__productWrap {
           margin: 0 15px;
        }
    }

}