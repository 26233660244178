.seriesQianjiPage {
    .seriesQianjiPage__info {
        position: relative;

        .seriesQianjiPage__info-img {
            width: 100%;
            height: auto;
            font-size: 0;
        }

        .seriesQianjiPage__infoContent {
            position: absolute;
            left: 0;
            bottom: 60px;
            width: 100%;
            text-align: center;
            .seriesQianjiPage__infoContent-tl {
                font-family: xiaowei;
                font-size: 21px;
                color: #FFFFFF;
            }
            .seriesQianjiPage__infoContent-text {
                margin-top: 10px;
                p {
                    font-family: SourceHanSansSC;
                    font-size: 12px;
                    color: #ECE8E5;
                    line-height: 2.4;
                }
            }
        }

    }

    .seriesQianjiPage__middleBg {
        margin-top: -10px;
        padding-bottom: 20px;
        background: url(../../assets/series/qianji/info_2.png) no-repeat;
        background-size: cover;
        .seriesQianjiPage__product {
            .seriesQianjiPage__productWrap {
                margin: 0 15px;
            }
        }
    }

    .seriesQianjiPage__infoThree {
        position: absolute;
        left: 0;
        top: 100px;
        width: 100%;
        text-align: center;
        p {
            font-family: SourceHanSansSC;
            font-size: 12px;
            color: #ffffff;
            line-height: 2.4;
        }
    }

}
