.seriesRelianPage {
    .seriesRelianPage__info {
        position: relative;

        .seriesRelianPage__info-img {
            width: 100%;
            height: auto;
            font-size: 0;
        }

        .seriesRelianPage__infoContent {
            position: absolute;
            left: 0;
            bottom: 10px;
            width: 100%;
            z-index: 1;
            text-align: center;

            .seriesRelianPage__infoContent-tl {
                font-family: AlimamaShuHeiTi;
                font-weight: bold;
                font-size: 21px;
                color: #F1EEE7;
            }

            .seriesRelianPage__infoContent-text {
                padding-top: 20px;

                p {
                    font-family: SourceHanSansSC;
                    font-size: 12px;
                    color: #F1EEE7;
                    line-height: 2;
                }
            }
        }
    }

    .seriesYihuoPage__infoTwo {
        position: absolute;
        left: 0;
        bottom: 20px;
        width: 100%;
        text-align: center;

        p {
            font-family: SourceHanSansSC;
            font-size: 12px;
            color: #D5D1D0;
            line-height: 2;
        }
    }

    .seriesYihuoPage__infoThree {
        position: absolute;
        left: 0;
        bottom: 80px;
        width: 100%;
        text-align: center;
        p {
            font-family: SourceHanSansSC;
            font-size: 12px;
            color: #151515;
            line-height: 2;
        }
    }

    .seriesRelianPage__foot {
        margin-top: -10px;
        background: url(../../assets/series/relian/info_4.png) no-repeat;
        background-size: 100% auto;
        .seriesRelianPage__product {
            padding-top: 20px;
            .seriesRelianPage__productWrap {
               margin: 0 15px;
            }

        }
        .seriesRelianPage__footer {
            padding: 50px 0 80px 0;
            text-align: center;

            .seriesRelianPage__footer-img {
                width: 48px;
                height: 64px;
            }
        }
    }
}


