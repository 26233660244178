.shopPage {
    .shopPage_info {
        position: relative;

        .shopPage_info-img {
            width: 100%;
            height: auto;
            font-size: 0;
        }

        .shopPage_title {
            position: absolute;
            bottom: 60px;
            left: 35%;
            text-align: center;

            .shopPage_title-cn {
                font-family: SourceHanSansCN-Bold;
                font-size: 15px;
                color: #F1EEE7;
            }

            .shopPage_title-en {
                font-family: CenturyGothic;
                font-size: 12px;
                color: #F1EEE7;
                transform: scale(0.9);
            }
        }
        .shopPage_line{
            position: absolute;
            bottom: 0px;
            left: 15px;
            right: 15px;
            height: 1px;
            background: #656565;
        }
    }

    .shopPage_foot {
        margin-top: -10px;
        padding: 80px 0;
        background: url(../../assets/shop/info_2.png) no-repeat;
        background-size: cover;

        .shopPage_shops {
            .shopPage_shopsRow {
                margin: 0 15px;
                display: flex;
                padding-bottom: 10px;
                .shopPage_shopsLeft {
                    width: 35%;
                    text-align: left;

                    .shopPage_shopsLeft-cn {
                        font-family: CenturyGothic;
                        font-size: 17px;
                        color: #F1EEE7;
                    }

                    .shopPage_shopsLeft-en {
                        font-family: CenturyGothic;
                        font-size: 12px;
                        color: #F1EEE7;
                    }
                }

                .shopPage_shopsRight {
                    width: 65%;
                    text-align: left;
                    .shopPage_shopItem {
                        margin-bottom: 30px;

                        .shopPage_shopItem-name {
                            font-family: SourceHanSansCN;
                            font-size: 16px;
                            color: #F1EEE7;
                        }

                        .shopPage_shopItem-address {
                            font-family: SourceHanSansCN;
                            font-size: 12px;
                            color: #F1EEE7;
                        }
                    }

                    .shopPage_qrcode {
                        padding-top: 80px;
                        .shopPage_qrcode-img {
                            width: 72px;
                            height: 72px;
                        }

                        .shopPage_qrcode-text {
                            font-family: SourceHanSansSC;
                            font-size: 12px;
                            transform: scale(0.85);
                            color: #F1EEE7;
                            margin-left: -20px;
                        }
                    }
                }
            }
        }
    }
}