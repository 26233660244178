.contactPage {
    .contactPage_info {
        position: relative;
        .contactPage_info-img {
            width: 100%;
            height: auto;
            font-size: 0;
        }
    }
    .contactPage__code {
        position: absolute;
        width: 100%;
        left: 0;
        top: 120px;
        .contactPage__codeRow {
            margin: 30px 20px;
            display: flex;
            justify-content: space-around;
            .contactPage__codeCol {
                flex: 1;
                text-align: center;
                .contactPage__codeCol-img {
                    width: 80px;
                    height: 80px;
                }

                .contactPage__codeCol-name {
                    margin-top: 10px;
                    font-family: SourceHanSansSC;
                    font-size: 12px;
                    color: #151515;
                }

                .contactPage__codeCol-desc {
                    font-family: CenturyGothic;
                    font-size: 12px;
                    color: #151515;
                    transform: scale(0.8);
                }
            }
        }
    }
}


