.homePage {
    position: relative;

    .homePage_info {
        position: relative;

        .homePage_info-img {
            width: 100%;
            height: auto;
            font-size: 0;
        }

    }

    .homePage__mainWrap {
        background-color: #000;
        .homePage__videoWrap{
             margin-top: -10px;
            display: block;
        }
        .homePage__avant-img {
            width: 100%;
            height: auto;
        }
    }

    .homePage__foot {
        margin-top: -10px;
        background: url(../../assets/home/main_bg.png) no-repeat;
        background-size: cover;
        .homePage__solganRow {
            display: flex;
            justify-content: space-between;
            padding: 15px 10px 40px 10px;
            .homePage__solganLeft {
                font-family: SourceHanSansSC;
                font-size: 12px;
                color: #A9A9A9;

                .homePage__solganLeft-b {
                    margin-right: 5px;
                    font-family: SourceHanSansCN-Bold;
                    font-size: 16px;
                }
            }

            .homePage__solganRight {
                cursor: pointer;

                .homePage__solganRight-span {
                    font-family: SourceHanSansSC;
                    font-size: 12px;
                    vertical-align: middle;
                    color: #A9A9A9;
                }

                .homePage__solganRight-img {
                    margin-left: 5px;
                    width: 12px;
                    height: 12px;
                    vertical-align: middle;
                }
            }
        }

        .homePage__footer {
            padding: 50px 0;
        }
    }


}