  .productList {
      display: flex;
      gap: 8px;
      padding-bottom: 20px;
      .productItem {
        flex: 1;
        text-align: center;
          .productItem-img {
              width: 100%;
              height: auto;
          }

          .productItem-name {
              padding-bottom: 20px;
              font-family: SourceHanSansSC;
              font-size: 12px;
              color: #000000;
          }
      }
  }