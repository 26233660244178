.brandPage {
    .brandPage_info {
        position: relative;

        .brandPage_info-img {
            width: 100%;
            height: auto;
            font-size: 0;
        }

        .brandPage__infoContent {
            position: absolute;
            left: 0;
            top: 120px;
            width: 100%;
            z-index: 1;
            text-align: center;

            .brandPage__infoContent-tl {
                font-family: SourceHanSansCN-Bold;
                font-size: 16px;
                color: #000000;
            }

            .brandPage__infoContent-en {
                font-family: CenturyGothic;
                font-size: 13px;
                color: #000000;
                transform: skew(-15deg);
            }

            .brandPage__infoContent-text {
                padding-top: 20px;

                p {
                    margin: 0;
                    font-family: SourceHanSansSC;
                    font-size: 12px;
                    color: #000000;
                    line-height: 2.4;

                     span{
                        font-family: CenturyGothic;  
                    }

                }
            }
        }
    }

    .brandPage__footer {
        position: absolute;
        width: 100%;
        left: 0;
        bottom: 60px;
        text-align: center;
        .brandPage__footerContent {
            margin: 60px 30px;
            .brandPage__footerContent-text {
                margin-top: 30px;
                text-align: justify;
                line-height: 1.8;
                font-family: CenturyGothic;
                font-size: 12px;
                color: #000000;
            }
        }

        .brandPage__footer-img {
            width: 50px;
            height: 70px;
        }
    }
}


