.seriesAnyePage {
    .seriesAnyePage__info {
        position: relative;

        .seriesAnyePage__info-img {
            width: 100%;
            height: auto;
            font-size: 0;
        }

        .seriesAnyePage__infoContent {
            position: absolute;
            left: 0;
            bottom: 60px;
            width: 100%;
            text-align: center;

            .seriesAnyePage__infoContent-tl {
                font-family: xiaowei;
                font-size: 20px;
                color: #D6D6D6;
            }

            .seriesAnyePage__infoContent-text {
                margin-top: 10px;

                p {
                    margin-top: 0;
                    margin-bottom: 5px;
                    font-family: SourceHanSansSC;
                    font-size: 12px;
                    line-height: 1.5;
                    color: #d6d6d6;
                }

                div {
                    line-height: 1;
                }
            }
        }

        .seriesAnyePage__infoTwo {
            position: absolute;
            left: 0;
            bottom: 50px;
            width: 100%;
            text-align: center;
            p {
                font-family: SourceHanSansSC;
                font-size: 12px;
                line-height: 2;
                color: #d6d6d6;
            }
        }

    }

    .seriesAnyePage__product {
        margin-top: -10px;
        padding-top: 50px;
        background: url(../../assets/series/anye/info_3.png) no-repeat;
        background-size: 100% auto;

        .seriesAnyePage__productWrap {
            margin: 0 15px;
        }

    }
}