.seriesLiehuangPage {
    .seriesLiehuangPage__info {
        position: relative;

        .seriesLiehuangPage__info-img {
            width: 100%;
            height: auto;
            font-size: 0;
        }

        .seriesLiehuangPage__infoContent {
            position: absolute;
            left: 0;
            bottom: 20px;
            width: 100%;
            z-index: 1;
            text-align: center;

            .seriesLiehuangPage__infoContent-tl {
                padding-bottom: 10px;
                font-family: PangMenZhengDao;
                font-size: 24px;
                color: #000000;
            }

            .seriesLiehuangPage__infoContent-text {
                padding-bottom: 10px;
                p {
                    font-family: SourceHanSansSC;
                    font-weight: 400;
                    font-size: 12px;
                    color: #000000;
                    line-height: 2;

                }
            }
        }

        .seriesLiehuangPage__infoTwo {
            position: absolute;
            left: 0;
            bottom: 40px;
            width: 100%;
            text-align: center;

            p {
                font-family: SourceHanSansSC;
                font-size: 12px;
                color: #000000;
                line-height: 2;
                transform: scale(0.9);
            }
        }

        .seriesLiehuangPage__infoThree {
            position: absolute;
            left: 0;
            bottom: 10px;
            width: 100%;
            text-align: center;

            p {
                font-family: SourceHanSansSC;
                font-size: 12px;
                color: #000000;
                line-height: 2;
                transform: scale(0.9);
            }
        }

        .seriesLiehuangPage__infoFour {
            position: absolute;
            left: 0;
            top: 100px;
            width: 100%;
            text-align: center;

            p {
                font-family: SourceHanSansSC;
                font-size: 12px;
                color: #000000;
                line-height: 3;
                transform: scale(0.9);
            }
        }
    }

    .seriesLiehuangPage__product {
        margin-top: -10px;
        padding-top: 30px;
        background: url(../../assets/series/liehuang/info_3.png) no-repeat;
        background-size: cover;

        .seriesLiehuangPage__productWrap {
            margin: 0 15px;
        }
    }
}
