.homePage__fourRow {
    margin-top: 30px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .homePage__fourCol {
        width: 49%;
        .homePage__fourCol-imgWrap {
            .homePage__fourCol-img {
                width: 100%;
                height: auto;
            }
        }

        .homePage__fourCol-textWrap {
            padding: 5px 0;
            text-align: center;
            color: #F1F1F1;

            .homePage__fourCol-title {
                font-family: SourceHanSansCN-Bold;
                font-size: 12px;

            }

            .homePage__fourCol-desc {
                font-family: SourceHanSansSC;
                font-size: 12px;
                transform: scale(0.9);
            }
        }
    }
}
