.headerContainer {
    position: fixed;
    left: 0;
    top: 0;
    z-index: 100;
    width: 100%;
    height: auto;
    background: none;
    transition: all .4s ease-in;
    -moz-transition: all .4s ease-in;
    -webkit-transition: all .4s ease-in;
    &.in {
        background: rgba(0, 0, 0, 0.6);
    }

    .header__row {
        margin: 0 12px;
        padding: 12px 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .header__menu {
            .header__menu-img {
                width: 20px;
                height: 20px;
            }
        }

        .header__logo {
            .header__logo-img {
                width: 28px;
                height: 38px;
            }
        }


        .header__search {}
    }
}



.headerOverplay {
    position: fixed;
    left: 0;
    top: 0;
    z-index: 1000;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 1);
    opacity: 1;
    transition: all .4s ease-in;
    -moz-transition: all .4s ease-in;
    -webkit-transition: all .4s ease-in;
    overflow-y: auto;

    .headerContainer__row {
        display: flex;
        justify-content: flex-start;
        padding: 10px 15px 10px 15px;
        margin-top: 30px;
        background: #12161B;

        .headerContainer__menuIcon {
            margin-top: 5px;
            .headerContainer__menu-image {
                width: 15px;
                height: 15px;
            }
        }

        .headerContainer__name {
            margin-left: 20px;
            text-align: center;
            font-size: 20px;
            font-family: SourceHanSansCN-Bold;
            color: #FFFFFF;
        }
    }


    .headerContainer__menu {
        margin-top: 20px;

        .headerContainer__menuCol {
            padding: 20px 20px;
            height: auto;

            .headerContainer__menu-title {
                font-size: 20px;
                font-family: SourceHanSansCN-Bold;
                color: #FFFFFF;
            }

            .headerContainer__items {
                margin-top: 15px;

                .headerContainer__menu-itemLink {
                    display: inline-block;
                    margin-right: 15px;
                    font-size: 12px;
                    line-height: 26px;
                    color: #FFFFFF;
                    font-family: SourceHanSansSC;
                }
            }
        }
    }
}