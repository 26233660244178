.homeBrand {
    .homeBrand__info {
        position: relative;

        .homeBrand__info-img {
            width: 100%;
            height: auto;
            font-size: 0;
        }

        .homeBrand__infoText {
            position: absolute;
            top: 60px;
            left: 10%;
            right: 10%;

            .homeBrand__infoText-tl {
                font-family: CenturyGothic;
                font-size: 16px;
                color: #010101;
            }

            .homeBrand__infoText-text {
                margin-top: 10px;
                font-family: SourceHanSansSC;
                font-size: 12px;
                color: #010101;
                line-height: 2;
                 span{
                    font-family: CenturyGothic;  
                }
            }

            .homeBrand__infoMore {
                margin-top: 30px;
                display: block;

                .homeBrand__infoMore-span {
                    vertical-align: middle;
                    font-family: SourceHanSansSC;
                    font-size: 12px;
                    color: #000000;
                }
                .homeBrand__infoMore-arrow{
                    vertical-align: middle;
                    margin-left: 2px;
                    display: inline-block;
                    width: 12px;
                    height: 12px;
                    background: url(../../../../assets/home/arrow_right_black.png) no-repeat;
                    background-size: 100% 100%;
                }
            }
        }

    }
}