.footerContainer {
    .footerContainer__row {
        text-align: center;

        .footerContainer__logo {
            .footerContainer__logo-img {
                width: 40px;
                height: 54px;
            }
        }

        .footerContainer__sologan {
            margin-top: 10px;
            font-family: SourceHanSansCN-Medium;
            font-size: 12px;
            color: #ffffff;
        }
    }
}
